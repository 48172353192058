import http from '@/utils/http';

// 获取
export const getSportList = (params) => {
    return http.request({
        url: "/v1/olympics/sport/list",
        method: 'get',
        params: params,
    })
}

// 更新
export const updateSport = (params) => {
    return http.request({
        url: "/v1/olympics/sport/update",
        method: 'post',
        data: params,
    })
}

// 搜索
export const searchSport = (params) => {
    return http.request({
        url: "/v1/olympics/sport/search",
        method: 'post',
        data: params,
    })
}
